import { InputText } from "primereact/inputtext";

export const CustomInput = ({
    label,
    name,
    maxLength,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    col = 4,
    inputClass,
    disabled = false,
    type = 'text',
    ...props
}) => {
    return (
            <InputText
                id={name}
                name={name}
                maxLength={maxLength}
                value={value || data?.[name] || ''}
                type={type}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                className={`w-full ${inputClass ? inputClass : ''} ${errorMessage ? 'p-invalid' : ''}`}
                disabled={disabled}
                {...props}
            />
    );
};