// AdminHome.js
import React, { useState } from "react";
import { LightButton } from "../../../shared/Button";
import CustomDialog from "../../../shared/CustomDialog";
import { CustomInput } from "../../../shared/InputText";
import CustomTable from "../../../shared/CustomTable";

const AdminHome = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    rank: "",
    password: "",
  });

  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const handleChange = ({ name, value }) => {
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const validateForm = () => {
    const newErrors = {};

    // Validate name (e.g. required and min length)
    if (!data.name) {
      newErrors.name = "Name is required.";
    } else if (data.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
    }

    // Validate email (e.g. required and must be a valid email)
    if (!data.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Email must be valid.";
    }

    // Validate phone (e.g. required and must be a valid phone number)
    if (!data.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(data.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    // Validate rank (e.g. required)
    if (!data.rank) {
      newErrors.rank = "Rank is required.";
    }

    // Validate password (e.g. required and minimum length)
    if (!data.password) {
      newErrors.password = "Password is required.";
    } else if (data.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {

    if (!validateForm()) {
      return;
    }
    // Push the current data state into the users array
    setUsers((prevUsers) => [...prevUsers, data]);

    // Reset the data state to clear the input fields
    setData({
      name: "",
      email: "",
      phone: "",
      rank: "",
      password: "",
    });

    // Close the dialog
    setOpen(false);
  };

  const columns = [
    { field: 'name', header: ' Name' },
    { field: 'email', header: 'Email' },
    { field: 'phone', header: 'Phone Number' },
    { field: 'rank', header: 'Rank' },
];

  return (
    <>
     <div className="m-0  right-0">
      <LightButton
        label="Add New User"
        className="relative right-5"
        onClick={() => setOpen(true)}
      />
      <CustomTable data={users} columns={columns}  />
      <CustomDialog
        title={"Add User"}
        visible={open}
        onCancel={onClose}
        loading={loading}
        onSave={handleSave}
      >
        <div className="grid">
          <div className="col-12">
          <label>
            Name
          </label>
          <CustomInput name="name" data={data} onChange={handleChange} />
          {errors.name && (
              <div className="error-message text-xs open-sans">{errors.name}</div>
            )}
          </div>
          <div className="col-12">
          <label>
            Email
          </label>
          <CustomInput name="email" data={data} onChange={handleChange} />
          {errors.email && (
              <div className="error-message text-xs open-sans">{errors.email}</div>
            )}
          </div> <div className="col-12">
          <label>
            Phone
          </label>
          <CustomInput name="phone" data={data} onChange={handleChange} />
          {errors.phone && (
              <div className="error-message text-xs open-sans">{errors.phone}</div>
            )}
          </div> <div className="col-12">
          <label>
          Rank
          </label>
          <CustomInput name="rank" data={data} onChange={handleChange} />
          {errors.rank && (
              <div className="error-message text-xs open-sans">{errors.rank}</div>
            )}
          </div> <div className="col-12">
          <label>
            Password
          </label>
          <CustomInput name="password" data={data} onChange={handleChange} />
          {errors.password && (
              <div className="error-message text-xs open-sans">{errors.password}</div>
            )}
          </div>
        </div>
       
   
      </CustomDialog>
      {/* Your CRUD operations for users would go here */}
    </div>
    <div>
       

    </div>
    </>
   
  );
};

export default AdminHome;
