import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Login from './components/Login/Login';
import Main from './Main';
import App from './App';
import ProtectedRoute from './util/ProtectedRoute';
import AdminLogin from './components/admin/adminLogin';
import AdminHome from './components/admin/adminHome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "primereact/resources/themes/mira/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='/admin' element={<AdminLogin />} />
        <Route path='/adminHome' element={<AdminHome />} />
        <Route path="/" element={<App />}>


          <Route path='' element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          } />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
