import React, { useState } from 'react';
import './login.css';
import { useNavigate } from "react-router-dom";
import CustomDialog from '../../shared/CustomDialog';
import { CustomInput } from "../../shared/InputText";
import { apiFunctions } from '../../apiFunction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';

export default function Login() {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    password: "",
    confirm_password: ""
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const onClose = () => {
    setOpen(false);
  };

  const handleChange = ({ name, value }) => {
    if (name === 'mobile') {
      if (/^\d*$/.test(value)) {
        setData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleLoginDemo = async () => {
    try {
      setLoading(true);
      const body = { "mobile": mobileNumber, "password": password };
      const response = await apiFunctions.login(body);
      console.log("Respisne 41>>", response?.data?.message)
      if (response.status === 200) {
        if (response?.data?.message) {
          console.log("44")
          toast.error(response?.data?.message, { duration: 4000 });
        } else {
          toast.success('Signin successful!', { duration: 4000 });
          localStorage.setItem('mobileNumber', mobileNumber);
          localStorage.setItem('jwtToken', response?.data?.jwt_token);
          setTimeout(() => {
            navigate('/');
          }, 500);
        }

      } else {
        toast.error('Verification failed!', { duration: 4000 });
        setErrorMessage('Verification Failed!');
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.', { duration: 4000 });
      setErrorMessage('An unexpected error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!data.name) {
      newErrors.name = "Name is required.";
    } else if (data.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
    }

    if (!data.mobile) {
      newErrors.mobile = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(data.mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits.";
    }

    if (!data.password) {
      newErrors.password = "Password is required.";
    } else if (data.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    if (data.password !== data.confirm_password) {
      newErrors.confirm_password = "Passwords do not match.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await apiFunctions.signUp(data);
      console.log("signup failer>>", response)
      if (response?.data.message) {
        toast.error(response?.data?.message, { duration: 4000 })
      } else {
        toast.success('Signup successful!', { duration: 4000 })
      }
      setData({
        name: "",
        mobile: "",
        password: "",
        confirm_password: ""
      });
      setOpen(false);
    } catch (error) {
      toast.error('Signup failed. Please try again later.', { duration: 4000 });
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Check if the value is numeric
    if (/^\d*$/.test(value)) {
      setMobileNumber(value);
    }
  };
  return (
    <div className="form-container">
      <div className="logo-container">
        Login With Mobile Number
      </div>
      <div className="form">
        <div className="form-group">
          <label htmlFor="mnumber">Mobile Number</label>
          <input
            type="text"
            id="mnumber"
            name="mobile"

            maxLength={10}
            placeholder="Enter your number"
            value={mobileNumber}
            required
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Enter your password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: showPassword ? 'red' : 'black',
              }}
            />
          </div>
        </div>
        <button className="form-submit-btn" onClick={handleLoginDemo}>Verify</button>
        <div style={{ flexDirection: 'row' }}>
          <label htmlFor="register">Don't have an account?</label>
          <span onClick={() => setOpen(true)} style={{ textDecoration: 'underline', marginLeft: 10 }}>Register now</span>
        </div>
      </div>
      <p className="error-message">{errorMessage}</p>
      <CustomDialog
        title="Add User"
        visible={open}
        onCancel={onClose}
        loading={loading}
        onSave={handleSave}
      >
        <div className="grid">
          <div className="col-12">
            <label>Name</label>
            <CustomInput name="name" data={data} onChange={handleChange} />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          <div className="col-12">
            <label>Mobile</label>
            <CustomInput maxLength={10} name="mobile" data={data} onChange={handleChange} />
            {errors.mobile && <div className="error-message">{errors.mobile}</div>}
          </div>
          <div className="col-12">
            <label>Password</label>
            <CustomInput name="password" data={data} onChange={handleChange} />
            {errors.password && <div className="error-message">{errors.password}</div>}
          </div>
          <div className="col-12">
            <label>Confirm Password</label>
            <CustomInput name="confirm_password" data={data} onChange={handleChange} />
            {errors.confirm_password && <div className="error-message">{errors.confirm_password}</div>}
          </div>
        </div>
      </CustomDialog>
    </div>
  );
}
