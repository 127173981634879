import axios from 'axios';

const BASE_URL = 'https://manage-roborakhwala.com/v1api/';
export const apiFunctions = {
    createFormData: (reqObj) => {
        let formData = new FormData()
        for (const property in reqObj) {
            formData.append(property, reqObj[property])
        }
        return formData;
    },
    getPrivacyPolicy: async () => {
        const res = await axios.get(BASE_URL + 'privacy_policy')
        return res.data.data.description
    },
    getTermsAndConditions: async () => {
        const res = await axios.get(BASE_URL + 'terms_conditions')
        return res.data.data.description
    },
    signUp: async (data) => {
        // console.log({ data })
        try {
            const response = await axios.post(BASE_URL + 'user_registry', data)
            console.log('Response is', response.data)
            return response;
        } catch (error) {
            // Handle error appropriately
            console.log('Error signup:', error);
            return { error: error.message }; // or any other default error handling
        }
    },
    login: async (data) => {
        try {
            const response = await axios.post(BASE_URL + 'validate_user', data)
            console.log('🚀 ~ login: ~ response:', response)
            return response;
        } catch (error) {
            console.error('Error: login', error);
            return { error: error.message };
        }
    },
    getUserAction: async (data) => {
        try {
            const response = await axios.post(BASE_URL + 'get_user_profile_details', data)
            console.log('🚀 ~ getUserAction: ~ response:', response)
            return response;
        } catch (error) {
            console.error('Error: getUserAction', error);
            return { error: error.message };
        }
    },
    updateTaks: async (data) => {
        try {
            const response = await axios.post(BASE_URL + 'update_task', data)
            console.log('🚀 ~ updateTaks: ~ response:', response)
            return response;
        } catch (error) {
            console.error('Error: updateTaks', error);
            return { error: error.message };
        }
    }
}