import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css'; // Import CSS file for styling

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username === 'admin' && password === 'admin') {
            navigate('/adminHome'); // Use navigate instead of history
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="admin-login-container">
            <h2 style={{color:'white'}}>Admin Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username:</label>
                    <input className="input-field" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Password:</label>
                    <input className="input-field" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button className="submit-button" type="submit">Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
