import React from "react";
import useSound from "use-sound";
import clickSfx from "../../sounds/slide.mp3";
import { Actions } from "../../actions/actions";
import { CapitalizeFirstLetter } from "../../util/commonFunctions";

const Controls = ({ timerMode, setTimerMode, volume ,data}) => {
  const [playSfx] = useSound(clickSfx, { volume: volume });

  const handleModeChange = (event) => {
    setTimerMode(event.target.id);
  };

  return (
    <form className="controls">
      {data.map((item,i) => (
        <>
          <input
            type="radio"
            id={item?.name}
            name="mode"
            checked={timerMode === item.name}
            onClick={playSfx}
            onChange={handleModeChange}
          />
          <label htmlFor={item?.name} className="controls__button">
            {CapitalizeFirstLetter(item?.name)}
          </label>
        </>
      ))}
    </form>
  );
};

export default Controls;
