import React, { useState, useEffect } from 'react';
import './App.css';
import Controls from './components/Controls/controls';
import TimerDisplay from './components/TimerDisplay/timerdisplay';
import Button from './components/Button/button';
import Settings from './components/Settings/settings';
import { useNavigate } from "react-router-dom";
import useSound from 'use-sound';
import timesUpSfx from './sounds/timesUp.mp3';
import { Actions } from './actions/actions';
import { apiFunctions } from './apiFunction';

function Main() {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [timerMode, setTimerMode] = useState();   // options: action1, action2
  const [timers, setTimers] = useState({});
  const [volume, setVolume] = useState(5);
  const [token, setToken] = useState();
  const [error, setError] = useState(null); // State for error message
  const navigate = useNavigate();
  const [timesUp] = useSound(timesUpSfx, { volume: volume });
  const [newTimes, setNewTimes] = useState(0);

  useEffect(() => {
    getDataFromApi();
  }, []);

  const updateTimes = (timerMode, name) => {
    if (name === 'increaseDuration') {
      setNewTimes(newTimes + 1);
    } else if (name === 'decreaseDuration' && newTimes > 0) {
      setNewTimes(newTimes - 1);
    }
  }

  const getDataFromApi = async () => {
    try {
      const getMobile = await localStorage.getItem('mobileNumber');
      const getJwtToken = await localStorage.getItem('jwtToken');
      setToken(getJwtToken);
      const data = {
        user_login_id: getMobile,
        jwt_token: getJwtToken
      };
      const response = await apiFunctions.getUserAction(data);
      if (response.data && response.data.length > 0) {
        const newTimers = {};

        const deviceLabel = response.data[0]?.device_label;
        const deviceId = response.data[0]?.device_id;
        setTimerMode(deviceLabel || deviceId);

        response.data.forEach(action => {
          const label = action.device_label || action.device_id;
          newTimers[label] = {
            id: action.id,
            name: label || action.device_id, // Use device_id if label is empty
            initialDuration: action.duration,
            currentTime: action.duration * 60,
            isActive: false,
            buttonText: 'START',
            volume: 5,
          };
        });
        setTimers(newTimers);
      } else {
        setError('No data available from the API');
      }
    } catch (err) {
      setError('Failed to fetch data from the API');
    }
  };

  useEffect(() => {
    // Update the current time of each active timer
    const interval = setInterval(() => {
      setTimers(prevTimers => {
        const updatedTimers = { ...prevTimers };
        Object.keys(updatedTimers).forEach(device => {
          const timer = updatedTimers[device];
          if (timer.isActive && timer.currentTime > 0) {
            const newCurrentTime = timer.currentTime - 1;
            if (newCurrentTime === 0) {
              timesUp(); // Play sound when timer ends
              controlTimer(device, 'stop');
            }
            updatedTimers[device] = { ...timer, currentTime: newCurrentTime };
          }
        });
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timesUp]);

  const controlTimer = (device, action, newValue) => {
    setTimers(prevTimers => {
      const updatedTimers = { ...prevTimers };
      const timer = { ...updatedTimers[device] };
      switch (action) {
        case 'start':
          timer.isActive = true;
          timer.buttonText = 'STOP';
          break;
        case 'stop':
          timer.isActive = false;
          timer.buttonText = 'START';
          break;
        case 'reset':
          timer.initialDuration = 0;
          timer.currentTime = 0;
          timer.isActive = false;
          timer.buttonText = 'START';
          break;
        case 'volume':
          timer.volume = newValue;
          break;
        case 'buttonText':
          timer.buttonText = newValue;
          break;
        case 'increaseDuration':
          timer.initialDuration += newValue; // Adjust duration based on newValue
          timer.currentTime = timer.initialDuration * 60;
          break;
        case 'decreaseDuration':
          if (timer.initialDuration > 0) {
            timer.initialDuration -= newValue; // Adjust duration based on newValue
            timer.currentTime = timer.initialDuration * 60;
          }
          break;
        default:
          break;
      }
      updatedTimers[device] = timer;
      return updatedTimers;
    });
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const toggleSettingsVisibility = () => {
    setSettingsVisible(!settingsVisible);
  };

  const formatTimeLeft = seconds => {
    return (
      `${Math.floor(seconds / 60)}:${seconds % 60 > 9
        ? seconds % 60
        : '0' + seconds % 60}`
    );
  };

  const handleStop = () => {
    controlTimer(timerMode, 'stop');
    // callAPI({ mobileNumber: localStorage.getItem("mobileNumber"), actionIndex: Actions[timers[timerMode].id].actions, event: "stop", timeVal: timers[timerMode].currentTime });
  };

  const calcPercentage = timer => {
    return (timer.currentTime / (timer.initialDuration * 60)) * 100;
  };

  const handleNewTimeSubmit = () => {
    controlTimer(timerMode, 'increaseDuration', newTimes); // Use the newTimes value to update the current timer
    setNewTimes(0); // Reset newTimes after submitting
  };

  const handleReset = () => {
    controlTimer(timerMode, 'reset'); // Reset the current timer
  };

  return (
    <div className="timer-app">
      {error ? (
        <div className="error-popup">
          <h2>Error</h2>
          <p style={{color:'red'}}>{error}</p>
          <button onClick={() => setError(null)}>Close</button>
        </div>
      ) : (
        <>
          <div className='controls_section'>
            <Controls
              data={Object.values(timers)}
              timerMode={timerMode}
              setTimerMode={setTimerMode}
              volume={volume}
            />
            {/* <Button type="settings" toggleVisibility={toggleSettingsVisibility} /> */}
            <Button type="logout" buttonText='Sign Out  >' handleLogout={handleLogout} />
          </div>
          <TimerDisplay
            wholData={timers[timerMode]}
            token={token}
            key={timerMode}
            timerMode={timerMode}
            percentage={timers[timerMode] ? calcPercentage(timers[timerMode]) : 0}
            timeLeft={timers[timerMode] ? formatTimeLeft(timers[timerMode].currentTime) : '0:00'}
            isActive={timers[timerMode] ? timers[timerMode].isActive : false}
            setIsActive={newIsActive => controlTimer(timerMode, newIsActive ? 'start' : 'stop')}
            buttonText={timers[timerMode] ? timers[timerMode].buttonText : 'START'}
            setButtonText={newButtonText => controlTimer(timerMode, 'buttonText', newButtonText)}
            volume={timers[timerMode] ? timers[timerMode].volume : 5}
            setVolume={newVolume => controlTimer(timerMode, 'volume', newVolume)}
            handleStop={handleStop}
            initialTime={timers[timerMode] ? timers[timerMode].initialDuration * 60 : 0}
          />
          {timers[timerMode]?.initialDuration === 0 && (
            <>
              <div className="new-time-buttons">
                <button className="time-button" onClick={() => updateTimes(timerMode, 'increaseDuration')}>+</button>
                <h1 style={{ color: 'green', margin: 10 }}>{newTimes}</h1>
                <button className="time-button" onClick={() => updateTimes(timerMode, 'decreaseDuration')}>-</button>
              </div>
              <button className="submit-time-button" onClick={handleNewTimeSubmit}>Submit New Time</button>
            </>
          )}
          <button className="submit-time-button" onClick={handleReset}>
            <i className="fas fa-redo"></i> Reset
          </button>
          {/* <Settings
            visible={settingsVisible}
            toggleSettingsVisibility={toggleSettingsVisibility}
          /> */}
        </>
      )}
    </div>
  );
}

export default Main;
