import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useSound from 'use-sound';
import startSfx from '../../sounds/startTimer.mp3';
import pauseSfx from '../../sounds/pauseTimer.mp3';
import callAPI from './../../util/updateStatusAPI';
import { toast } from 'react-toastify';

const TimerDisplay = ({
  wholData,
  timerMode,
  percentage,
  timeLeft,
  isActive,
  setIsActive,
  buttonText,
  setButtonText,
  volume,
  setVolume,
  handleStop,
  token
}) => {
  const [apiResponse, setApiResponse] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mobileNumber = localStorage.getItem("mobileNumber");
  const [play] = useSound(startSfx, {
    interrupt: true,
    volume: volume,
  });
  const [pause] = useSound(pauseSfx, {
    interrupt: true,
    volume: volume,
  });

  useEffect(() => {
    if (timeLeft === '0:00') {
      callAPI({
        user_login_id: mobileNumber,
        jwt_token: token,
        actionIndex: timerMode,
        event: 'stop',
        duration: timeLeft,
        id: wholData?.id,
      }).then((res) => {
        setApiResponse(res?.data?.message);
        setIsModalOpen(true);
      });
    }
  }, [timeLeft, mobileNumber, token, timerMode, wholData]);

  useEffect(() => {
    const handleUnload = (event) => {
      if (isActive) {
        // Cancel the event to trigger beforeunload
        event.preventDefault();

        // Perform the API call
        callAPI({
          user_login_id: mobileNumber,
          jwt_token: token,
          actionIndex: timerMode,
          event: 'stop',
          duration: timeLeft,
          id: wholData?.id,
        });

        // If necessary, show a confirmation dialog (modern browsers ignore this string)
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [mobileNumber, token, timerMode, timeLeft, wholData, isActive]);

  const handleClick = () => {
    if (timeLeft === '0:00') {
      return null;
    }

    if (!isActive) {
      play();
      setIsActive(true);
      setButtonText('STOP');
    } else {
      pause();
      handleStop();
      setIsActive(false);
      setButtonText('START');
    }

    callAPI({
      user_login_id: mobileNumber,
      jwt_token: token,
      actionIndex: timerMode,
      event: isActive ? 'stop' : 'start',
      duration: timeLeft,
      id: wholData?.id,
    }).then(res => {
      toast.success(res?.data?.message, { duration: 400 })
      // setApiResponse(res?.data.message);
      // setIsModalOpen(true);
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  let timesUpMsg = '';
  let timeText = timeLeft === '0:00' ? timesUpMsg : timeLeft;
  let textSize = timeLeft === '0:00' ? '12px' : '28px';

  return (
    <>
      <div className="timer">
        <div className="timer__display">
          <CircularProgressbarWithChildren
            value={percentage}
            text={timeText}
            strokeWidth={4}
            styles={buildStyles({
              pathTransitionDuration: 0.5,
              pathColor: 'var(--accent-color)',
              textColor: 'var(--text)',
              textSize: textSize,
              fontFamily: 'var(--font-current)',
              trailColor: 'none',
            })}
          >
            <button className="display__start-pause" onClick={handleClick}>
              {buttonText}
            </button>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </>
  );
};

export default TimerDisplay;
