import { apiFunctions } from '../apiFunction';
import config from '../config';
// import { toast } from 'react-toastify';

const callAPI = async (payload) => {
  try {
    console.log(payload);
    const res = await apiFunctions.updateTaks(payload);

    // Show success toast
    // toast.success('Task updated successfully!', {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });

    return res;
  } catch (error) {
    // Show error toast
    // toast.error('Failed to update task.', {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });

    throw error;
  }
};

export default callAPI;
